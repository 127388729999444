// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const DATA_TABLE = gql`
  query ExportContactMoment(
    $filter: ContactMomentStatusFilter,
    $filters: [DataGridFilterInput],
    $sorters: [DataGridSorterInput]
  ) {
    export: exportContactMoment(
      filter: $filter,
      filters: $filters,
      sorters: $sorters
    ) {
      data
      name
    }
  }
`;

export default DATA_TABLE;
